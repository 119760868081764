.aboutWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 60vh;
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
}

.aboutContent {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: 90%;
  width: 95%;
  padding: 20px;
  max-width: 1700px;
}

.about-1 img {
  margin: 0 1rem;
  width: 45vw;
  max-width: 800px;
  height: 400px;
  object-fit: cover;
  object-fit: cover;
  border-radius: 10%;
  object-fit: cover;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
}

.about-2 {
  flex: 1;
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px;
}

.about-2 h4 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #21bcff;
}

.about-2 p {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #837e7e;
}

.vision-mission {
  margin-top: 1rem;
}

.vision-mission h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #323435;
}

@media screen and (max-height:860px) {
  .aboutWrapper {
    height: 73vh;
  }
}

@media screen and (max-width: 960px) {
  .aboutWrapper {
    height: 100%;
  }
  .aboutWrapper h2 {
    margin: 2rem 0;
  }
  .aboutContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0px;
  }

  .about-1 img {
    width: 70vw;
    height: 400px;
  }
  .about-2 {
    flex: 1;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
  }

  .about-2 h4 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #21bcff;
  }

  .about-2 p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: #837e7e;
  }
  .vision-mission {
    margin-top: 1rem;
  }
  .vision-mission h3 {
    margin-top: 0.5rem;
  }
}
