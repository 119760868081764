#products {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
    min-height: 100vh;
    padding: 20px;
    width: 95vw;
    min-width: 0;
}

.products-page-title {
    color: var(--rose);
    font-size: var(--extra-large-text);
}

div.titl-underline {
    background: var(--primary-3);
    width: 80vw;
    height: 0.1rem;
    margin: 0 auto;
    margin-bottom: 2rem;
}

.btn-container {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.filter-btn {
    background: transparent;
    border-color: transparent;
    font-size: 1rem;
    text-transform: capitalize;
    margin: 0 0.5rem;
    letter-spacing: 1px;
    padding: 0.375rem 0.75rem;
    color: var(--gold);
    cursor: pointer;
    transition: var(--transition);
    border: none;
}

.filter-btn:hover {
    border-radius: var(--radius);
    background: var(--gold);
    color: var(--white);
}

.border-btm {
    border-bottom: 1px solid var(--gold);
}

.item-text {
    text-align: left;
}

.products-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4rem 2rem;
    width: 80vw;
    max-width: 1700px;
}

.menu-item {
    display: flex;
    width: 550px;
    height: 250px;
    overflow: hidden;
    justify-content: center;

}

.photo {
    object-fit: cover;
    width: 280px;
    height: 250px;
    border: 0.25rem solid var(--gold);
    border-radius: var(--radius);
    display: block;
}

.item-info {
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
    width: 200px;
    height: 200px;
}

.item-info h4 {
    font-size: var(--small-text);
    color: var(--black);
}

.item-text {
    padding-top: 1rem;
    font-size: var(--extra-small-text);
    color: var(--grey-6);
    width: 200px;
    font-weight: bolder;
    /* max-height: 100px; */
}


@media screen and (max-width: 768px) {
    #products {
        max-width: 90vw;
    }

    .products-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .menu-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 500px;
        /* margin-bottom: 2rem; */
    }

    .photo {
        width: 100%;
        opacity: 0.5;
    }

    .item-info {
        justify-content: center;
        position: absolute;
        top: 60%;
        left: 35%;
        transform: translate(-50%, -50%);
        width: 300px;
        padding: 5px;
        padding-left: 30px;
        align-items: center;
    }

    .item-info h4 {
        text-align: center;
        margin-top: 0.5rem;
        font-size: var(--small-text);
    }

    .item-text {
        text-align: center;
        /* max-width: 65vw; */
        /* font-size: var(--extra-small-text); */
        color: var(--black);
        width: 100%;
        font-weight: bolder;

    }
}


@media screen and (max-width: 500px) {
    #products {
        max-width: 90vw;
        margin: 0 auto;
    }

    .menu-item {
        width: 300px;
        margin: 0 auto;
        /* margin-bottom: 2rem; */
    }

    .photo {
        width: 100%;
        opacity: 0.5;
    }

    /* .item-text {
     padding: 5px;
    } */
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  margin-top: 4rem;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--primary-3);
  color: var(--primary-3);
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: var(--primary-3);
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
