.service-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8rem;
}

.service-hero {
  width: 100%;
  height: 60vh;
  position: relative;
  text-align: center;
}


.service-hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.service-hero p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 60vw;
  max-width: 80vw;
  font-size: var(--large-text);
}

#service-cards {
  width: 100%;
  height: 40vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding: 20px;
  max-width: 1370px;
  margin: 0 auto;
  place-items: center;
  gap: 2rem;
}

#service-cards div {
  width: 70%;
  border-radius: 10px;
  padding: 10px;
  max-height: 25vh;
  box-shadow: var(--light-shadow);
  background-color: var(--grey-9);
  text-align: center;
  font-size: var(--small-text);
}

#service-cards div small {
  border-radius: 50%;
  display: block;
  margin: 20px auto;
  padding: 20px;
  height: 100px;
  width: 100px;
  background-color: var(--light-rose);
  position: relative;
}

.fa-icon-service {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;

}


#service-form {
  display: block;
  width: 100%;
  height: 90vh;
  margin: 0 auto;
  text-align: center;
}

#service-form .service-quote-form {
  background-color: var(--rose);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.service-quote-form h1 {
  margin: 1rem 0;
}

.service-quote-form Form {
  background-color: var(--primary-3);
  width: 55%;
  text-align: center;
  padding: 2rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--black);
  position: relative;
}

.service-quote-form Form input,
.service-quote-form Form textarea {
  background-color: var(--white);
  width: 100%;
  margin: 0.5rem 0;
}

.service-quote-form Form select,
.service-quote-form Form input::placeholder,
.service-quote-form Form textarea::placeholder {
  color: var(--grey-6);
}

.service-quote-form Form textarea {
  height: 8rem;
}

.service-quote-form Form select {
  margin: 0.5rem 0;
  height: 2rem;
  width: 40%;
  border-radius: 10px;
  font-size: 0.9rem;
  color: var(--black);
}

.service-quote-form label {
  font-weight: bold;
  display: flex;
  text-align: center;
  color: var(--white);
}

.quote-btn {
  background-color: var(--primary-3);
  padding: 1rem 2rem;
  border: none;
  border-radius: var(--radius);
  color: var(--white);
  font-weight: bold;
  margin: 2rem auto 0;
  box-shadow: var(--dark-shadow);
  cursor: pointer;
  letter-spacing: var(--spacing);
  transition: var(--transition);
  text-transform: capitalize;
  font-size: var(--small-text);
}


.quote-btn:hover {
  box-shadow: var(--btn-shadows1);
}

.service-quote-form input[type="email"],
.service-quote-form textarea[type="text"],
.service-quote-form input[type="text"],
.service-quote-form input[type="number"] {
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 2px solid var(--gray-200);
  background-color: var(--white);
  color: var(--gray-800);
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  border: 2px solid var(--grey-4);
  height: 1rem;
  width: 1rem;
  align-self: center;
  justify-content: center;
  position: relative;
  display: flex;
  background-color: var(--black);
  color: var(--white);
}

.checkmark-icon {
  position: absolute;
  bottom: 7%;
  right: 30%;
  height: 2rem;
  width: 2rem;
  color: var(--white);
  transition: all 0.2s ease-in-out;
}

.formik-error {
  font-size: 12px;
  color: var(--clr-red-dark);
  margin-top: 0.25rem;
}

@media (max-width: 768px) {
  .service-wrapper {
    margin-bottom: 5rem;
  }

  #service-cards {
    height: 90vh;
    width: 80vw;
    margin: 0 auto;
    text-align: center;
  }

  .service-hero p {
    height: 80vh;
    text-align: center;
    font-size: 20px;
    top: 80%;
  }


  .service-hero img {
    height: 100%;
  }

  .service-quote-form Form {
    width: 90%;
  }

  #service-form input[type="email"],
  #service-form input[type="text"],
  #service-form input[type="number"],
  #service-form textarea,
  #service-form select {
    max-width: 400px;
  }

  #service-form label {
    margin-top: 0.5rem;
  }

  #service-form label.checkbox {
    font-weight: normal;
  }

  #service-form button[type="submit"] {
    margin-top: 1rem;
  }

  .service-quote-form Form textarea {
    height: 4rem;
  }

  .checkmark-icon {
    right: 12%;
    height: 1.5rem;
    width: 1.5rem;
  }
}


@media (max-width: 400px) {
  .service-wrapper {
    margin-bottom: 9rem;
  }

  #service-cards {
    width: 80vw;
  }
}