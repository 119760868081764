
.swiper-products {
  width: 100%;
  padding-bottom: 50px;
  padding: 4rem 0;
  background-color: var(--grey-9);

}

.swiper-slide-products {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev {
  background-image: url("/src/images/accessories/icons8-arrow-50.png");
}
.swiper-button-next {
  background-image: url("/src/images/accessories/icons8-arrow-50.png");
  transform: rotate(180deg);
}

.swiper-button-next,
.swiper-button-prev {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 16px 20px;
  border-radius: 100%;
  border: 2px solid var(--rose);
  background-color: var(--rose);
  -webkit-tap-highlight-color: transparent;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: var(--rose);
}

.swiper-wrapper {
  transition-timing-function: linear;
}
