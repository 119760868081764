
.contact {
    height: 85vh;
    margin-bottom: 11rem ;
    width: 100%;
}

.underline {
    height: 10px;
    background: rgb(27, 168, 201);
    background: linear-gradient(90deg, rgb(243, 199, 4) 0%, rgba(196, 136, 108, 0.883) 35%, rgb(182, 8, 8) 100%);
}

.contact-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-3);
    color: var(--productImages);
    padding: 20px;
}

#form h2 {
    text-align: center;
    margin-top: 40px;
}

.form {
    width: 90vw;
    max-width: var(--fixed-width);
    background: var(--white);
    border-radius: var(--borderRadius);
    box-shadow: var(--light-shadow);
    padding: 2rem 2.5rem;
    margin: 3rem auto;
    transition: var(--transition);
}

.form:hover {
    box-shadow: var(--dark-shadow);
}

.form-success-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    gap: 20px;
}

.input {
    color: var(--grey-1);
}

.input:focus {
    outline-width: 0;
}

.form-label {
    display: block;
    font-size: var(--smallText);
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
}

.form-input,
.form-textarea,
.form-select {
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-radius: var(--borderRadius);
    background: var(--grey-7);
    border: 1px solid var(--clr-grey-3);
}

.form-input,
.form-select,
.btn-block {
    height: 35px;
}

.form-row {
    margin-bottom: 1rem;
}

.form-textarea {
    height: 7rem;
}

.form-alert {
    color: var(--red-dark);
    letter-spacing: var(--letterSpacing);
    text-transform: capitalize;
}

/* alert */

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
/* contact */
.contact-enquiries {
    margin-bottom: -30px;
    margin-top: 20px;
    color: var(--gold);
}

.contact-label {
    font-size: 15px;
    margin-top: 5px;
}


.contact-btn {
    background-color: var(--primary-3);
    padding: 1rem 2rem;
    border: none;
    border-radius: var(--radius);
    color: var(--gold);
    font-weight: bold;
    margin: 2rem auto 0;
    box-shadow: var(--dark-shadow);
    cursor: pointer;
    letter-spacing: var(--spacing);
    transition: var(--transition);
    text-transform: capitalize;
    display: block;
    width: 100%;
    font-size: var(--small-text);
}


.contact-btn:hover {
    box-shadow: var(--dark-shadow);
}

.contact-ul {
    width: 90vw;
    max-width: var(--fixed-width);
    text-align: center;
    color: var(--white);
}

.contact-ul p {
    margin: 10px;
}

.contact-ul ul li {
    list-style: none;
    display: inline;
}

.contact-ul small,
.contact-ul a {
    color: var(--rose);
    font-size: var(--small-text);
    text-decoration: none;

}

@media (min-width: 800px) {
    h2 {
        text-align: center;
    }

    /* contact */
    .event-img {
        width: 400px;
        height: 300px;
    }

    .contact-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        /* max-width: 1700px; */
    }

    .contact-ul {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 40px;
    }

    .btn-right {
        text-align: right;
    }

    .contact-btn {
        width: 200px;
        display: inline-block;
    }

    .contact-ul p {
        font-size: var(--large-text);
    }
    .contact {
        margin-bottom: -7.5rem;
    }
}

@media (max-height: 860px) {
    .contact {
        margin-bottom: 2.5rem;
    }
}