.swiper-hero {
  width: 100vw;
  height: calc(100vh - 80px);
  /* margin: 0 auto; */
}

.swiper-slider {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* width: 100%; */
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  -webkit-transform: translateZ(0);
}

.textwrap {
  max-width: 900px;
  text-align: center;
}

.textwrap h2 {
  font-size: 3.5em;
  line-height: 1.1;
  color: #fff;
  margin-bottom: 10px;
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
}


.textwrap p {
  font-size: 1.5em;
  color: #fff;
}


.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.hero-link {
  text-decoration: none;
  color: var(--white);
  
}

@media (max-width: 760px) and (orientation: landscape) {
  .swiper-slider {
    min-height: 110vh;
    padding: 70px 20px;
  }
}

@media (max-width: 991px) and (orientation: portrait) {
  .swiper-slider {
    min-height: 80vh;
    padding: 70px 20px;
  }
}

@media (max-width: 500px) {
  .swiper-hero {
    height: 84vh;
    width: 100%;
  }

  .swiper-slider {
    max-width: 100vw;
    /* margin: 0 auto; */
  }

  .textwrap {
    max-width: 70%;
    margin: 0 auto;
    margin-top: -150px;
  }

  .textwrap h2 {
    font-size: var(--large-text);
  }

  .textwrap p {
    font-size: var(--small-text);
  }

  .hero-btns .btn {
    max-width: 65vw;
  }
}