:root {
  --primary: #fff;
  --blue: #7dd2f6;
  --rose: #d57070;
}

.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 0 auto;
  color: var(--blue);
}

.contact-icon {
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: -2px;
  color: var(--rose);
}

.footer-link-items h2 {
  margin-bottom: 16px;
  color: var(--primary);
}

.footer-link-items p {
  margin-bottom: 15px;
}

.footer-link-items a {
  color: var(--blue);
  text-decoration: none;
}

.footer-link-items a:hover {
  color: var(--blue);
  transition: 0.3s ease-out;
}

/* Social Icons */
.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 20px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-icon-link {
  color: var(--rose);
  font-size: 24px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-container {
    padding: 2rem 1rem 2rem;
  }

  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .website-rights {
    margin: 20px 0;
  }

  .footer-link-items h2 {
    font-size: var(--small-text);
    padding: 0 25px;
  }

  .contact-icon {
    font-size: var(--small-text);
    padding-left: 5px;
  }

}