:root {
  --clr-red-dark: hsl(0, 100%, 60%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 21%, 21%);
  --clr-green-light: #19f849;
  --clr-green-lighter: #a5e6b3;

  --grey-1: hsl(210, 9%, 79%);
  --grey-2: hsl(212, 45%, 89%);
  --grey-3: hsl(210, 31%, 80%);
  --grey-4: hsl(180, 20%, 99%);
  --grey-5: hsl(209, 23%, 60%);
  --grey-6: hsl(206, 7%, 43%);
  --grey-7: #dae2ec;
  --grey-8: hsl(197, 42%, 54%);
  --grey-9: hsl(0, 8%, 86%);
  --text-color: #102a43;


  /* main colors */
  --gold: #a57600;
  --white: #fff;
  --primary-2: #0a6c74;
  --primary-3: #044e54;
  --blue: #7dd2f6;
  --dark-blue: #00a6ed;
  --rose: #d57070;
  --light-rose: #deb9b9;
  --black: #242424;

  /* fonts  */
  --headingFont: "Roboto Condensed", Sans-Serif;
  --bodyFont: "Cabin", Sans-Serif;
  --extra-large-text: 3.0rem;
  --large-text: 2.0rem;
  --small-text: 1.2rem;
  --extra-small-text: 1.1rem;

  --transition: all 0.8s ease;
  --spacing: 0.1rem;
  --radius: 0.5rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.9);
  --max-width: 1170px;
  --fixed-width: 620px;
  --btn-shadows1: linear-gradient(330deg,
      hsl(211deg 24% 81%) 0%,
      hsl(211deg 24% 79%) 1%,
      hsl(211deg 24% 76%) 3%,
      hsl(211deg 24% 74%) 4%,
      hsl(211deg 24% 72%) 6%,
      hsl(211deg 23% 69%) 9%,
      hsl(211deg 23% 67%) 12%,
      hsl(211deg 23% 65%) 16%,
      hsl(211deg 23% 63%) 22%,
      hsl(210deg 22% 61%) 41%,
      hsl(210deg 22% 59%) 62%,
      hsl(210deg 22% 56%) 70%,
      hsl(210deg 21% 54%) 76%,
      hsl(210deg 21% 52%) 80%,
      hsl(210deg 20% 50%) 84%,
      hsl(210deg 21% 48%) 88%,
      hsl(210deg 23% 46%) 91%,
      hsl(210deg 24% 45%) 93%,
      hsl(210deg 25% 43%) 96%,
      hsl(210deg 26% 41%) 98%,
      hsl(209deg 28% 39%) 100%);

  --btn-shadows2: linear-gradient(45deg,
      hsl(43deg 100% 32%) 0%,
      hsl(47deg 100% 30%) 22%,
      hsl(53deg 100% 28%) 40%,
      hsl(59deg 100% 25%) 55%,
      hsl(66deg 100% 25%) 66%,
      hsl(74deg 97% 26%) 75%,
      hsl(84deg 78% 29%) 81%,
      hsl(98deg 64% 32%) 85%,
      hsl(116deg 54% 35%) 89%,
      hsl(143deg 100% 27%) 100%);

  /* fonts  */
  --extra-large-text: 3.5rem;
  --large-text: 1.5rem;
  --small-text: 1.2rem;
  --extra-small-text: 0.8em;

  --transition: all 0.8s ease;
  --spacing: 0.1rem;
  --radius: 0.5rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.9);
  --max-width: 1170px;
  --fixed-width: 620px;
  --btn-shadows1: linear-gradient(330deg,
      hsl(211deg 24% 81%) 0%,
      hsl(211deg 24% 79%) 1%,
      hsl(211deg 24% 76%) 3%,
      hsl(211deg 24% 74%) 4%,
      hsl(211deg 24% 72%) 6%,
      hsl(211deg 23% 69%) 9%,
      hsl(211deg 23% 67%) 12%,
      hsl(211deg 23% 65%) 16%,
      hsl(211deg 23% 63%) 22%,
      hsl(210deg 22% 61%) 41%,
      hsl(210deg 22% 59%) 62%,
      hsl(210deg 22% 56%) 70%,
      hsl(210deg 21% 54%) 76%,
      hsl(210deg 21% 52%) 80%,
      hsl(210deg 20% 50%) 84%,
      hsl(210deg 21% 48%) 88%,
      hsl(210deg 23% 46%) 91%,
      hsl(210deg 24% 45%) 93%,
      hsl(210deg 25% 43%) 96%,
      hsl(210deg 26% 41%) 98%,
      hsl(209deg 28% 39%) 100%);

  --btn-shadows2: linear-gradient(45deg,
      hsl(43deg 100% 32%) 0%,
      hsl(47deg 100% 30%) 22%,
      hsl(53deg 100% 28%) 40%,
      hsl(59deg 100% 25%) 55%,
      hsl(66deg 100% 25%) 66%,
      hsl(74deg 97% 26%) 75%,
      hsl(84deg 78% 29%) 81%,
      hsl(98deg 64% 32%) 85%,
      hsl(116deg 54% 35%) 89%,
      hsl(143deg 100% 27%) 100%);
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "PT Sans", sans-serif;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;
}

p {
  margin: 0;
}

.fa-icon {
  margin-left: 6px;
  margin-bottom: -3px;
  color: #fff;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  min-width: 90vw;
  padding: 1rem;
}

.error-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #d57070;
  text-align: center;
  margin: 2rem;
}

.error-body {
  margin: 1rem;
  font-size: 1.5rem;
}

.error-link {
  display: block;
  margin: 2rem;
  list-style: none;
  color: #d57070;
  font-size: 1rem;
}

.error-img {
  margin: 2rem;
  width: 70%;
  height: 50%;
  object-fit: cover;
  box-shadow: var(--dark-shadow);
}

.h2 {
  font-size: var(--large-text);
  font-weight: 700;
  color: #d57070;
  margin: 1rem;
  text-align: center;
}


@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-5);
  border-radius: 50%;
  border-top-color: var(--gold);
  animation: spinner 2s linear infinite;
}

.loading-center {
  margin: 0 auto;
}

/* alert */
.alert-danger {
  border-radius: 10px;
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
  background: var(--clr-red-dark);
}

.alert-success {
  border-radius: 10px;
  background: var(--clr-green-light);
  text-align: center;
  padding: 5px 0;
  font-size: 10px;
}

.whatsapp {
  -webkit-tap-highlight-color: transparent;
  margin-right: 10px;

}

button,
button:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}